import React, { useEffect, useState, useCallback } from 'react';
import { placeBet } from '../services/websocket';
import countdown_mp3 from '../../../assets/sounds/countdown-sound.mp3';

const ActionButtons = ({ gameId, playerId, availableChips, handleBet, handleFold, handleRaiseClick, betAmount: initialBetAmount }) => {
  const [betAmount, setBetAmount] = useState(initialBetAmount);
  const [showSlider, setShowSlider] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(30); // 30 seconds countdown
  const [isAnimating, setIsAnimating] = useState(false); // To trigger animation in the last 5 seconds

  useEffect(() => {
    setBetAmount(initialBetAmount); // Update betAmount state if initialBetAmount changes
  }, [initialBetAmount]);

  // Memoized autoFold function to avoid recreating it on each render
  const autoFold = useCallback(() => {
    placeBet(gameId, playerId, 0, 'fold');
    if (handleFold) handleFold();
    resetBet();
  }, [gameId, playerId, handleFold]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          autoFold(); // Auto-fold when the timer hits 0
          clearInterval(countdownInterval); // Clear the interval once folded
          return 0;
        }
      });
    }, 1000); // Update countdown every second

    return () => clearInterval(countdownInterval); // Clear timer on component unmount
  }, [autoFold]); // Add autoFold as a dependency to this effect

  useEffect(() => {
    // Start animation and play sound in the last 5 seconds
    if (timeRemaining <= 5 && timeRemaining > 0) {
      setIsAnimating(true);
      playCountdownSound(); // Play countdown sound
    } else {
      setIsAnimating(false);
    }
  }, [timeRemaining]);

  const resetBet = () => {
    setBetAmount(0);
    setShowSlider(false);
    setTimeRemaining(30); // Reset timer back to 30 seconds
  };

  const onBetClick = () => {
    placeBet(gameId, playerId, betAmount, 'bet');
    if (handleBet) handleBet();
    resetBet();
  };

  const onCheckClick = () => {
    placeBet(gameId, playerId, 0, 'check');
    if (handleBet) handleBet();
    resetBet();
  };

  const onFoldClick = () => {
    autoFold();
  };

  const onRaiseClick = () => {
    setShowSlider(true); // Show the slider when Raise is clicked
  };

  const confirmRaise = () => {
    if (betAmount > 0 && betAmount <= availableChips) {
      placeBet(gameId, playerId, betAmount, 'raise');
      if (handleRaiseClick) handleRaiseClick();
      resetBet();
    } else {
      console.error("Raise amount must be greater than 0 and less than or equal to available chips.");
    }
  };

  const playCountdownSound = () => {
    const audio = new Audio(countdown_mp3); // Add the sound file path
    audio.play();
  };

  return (
    <div className="absolute bottom-10 right-10 flex flex-col space-y-4">
      {/* Countdown Timer Display */}
      <div className={`text-white font-bold ${isAnimating ? 'animate-pulse text-red-500' : ''}`}>
        Time remaining: {timeRemaining}s
      </div>

      {/* Slider for selecting raise amount */}
      {showSlider && (
        <div className="flex items-center">
          <span>Raise Amount: {betAmount}</span>
          <input
            type="range"
            min="0"
            max={availableChips}
            value={betAmount}
            onChange={(e) => setBetAmount(Number(e.target.value))}
            className="ml-2"
          />
        </div>
      )}
      <div className="flex space-x-4">
        {/* Conditionally render Bet or Check button based on betAmount */}
        {betAmount > 0 ? (
          <button
            className="bg-[#008000] text-white font-bold py-2 px-10 rounded-full hover:bg-green-600"
            onClick={onBetClick}
            disabled={betAmount > availableChips}
          >
            Bet: {betAmount}
          </button>
        ) : (
          <button
            className="bg-[#008000] text-white font-bold py-2 px-10 rounded-full hover:bg-green-600"
            onClick={onCheckClick}
          >
            Check
          </button>
        )}

        <button
          className="bg-[#008000] text-white font-bold py-2 px-10 rounded-full hover:bg-green-600"
          onClick={onFoldClick}
        >
          Fold
        </button>
        <button
          className="bg-[#008000] text-white font-bold py-2 px-10 rounded-full hover:bg-green-600"
          onClick={onRaiseClick}
        >
          Raise
        </button>
      </div>

      {/* Show Confirm Raise button if raise is selected and amount is greater than 0 */}
      {showSlider && betAmount > 0 && (
        <div className="flex justify-center">
          <button
            className="bg-yellow-500 text-white font-bold py-2 px-10 rounded-full hover:bg-yellow-600"
            onClick={confirmRaise}
          >
            Confirm Raise to {betAmount}
          </button>
        </div>
      )}
    </div>
  );
};

export default ActionButtons;
