import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cardBack from '../../../assets/images/card5.png';
// Import the sound effect
import cardFlipSound from '../../../assets/sounds/card-flip.mp3';

const CommunityCards = () => {
  const communityCards = useSelector((state) => state.game.communityCards || {
    flop_card1: null,
    flop_card2: null,
    flop_card3: null,
    turn_card: null,
    river_card: null,
  });

  const [flippedCards, setFlippedCards] = useState({
    flop_card1: false,
    flop_card2: false,
    flop_card3: false,
    turn_card: false,
    river_card: false,
  });

  useEffect(() => {
    const audio = new Audio(cardFlipSound); // Create a new Audio object

    // Helper function to flip cards and play sound
    const flipCardWithSound = (cardType, delay) => {
      setTimeout(() => {
        setFlippedCards((prev) => ({ ...prev, [cardType]: true }));
        audio.play(); // Play the card flip sound
      }, delay);
    };

    if (communityCards.flop_card1 !== null) {
      flipCardWithSound('flop_card1', 500);
    }
    if (communityCards.flop_card2 !== null) {
      flipCardWithSound('flop_card2', 1000);
    }
    if (communityCards.flop_card3 !== null) {
      flipCardWithSound('flop_card3', 1500);
    }
    if (communityCards.turn_card !== null) {
      flipCardWithSound('turn_card', 2000);
    }
    if (communityCards.river_card !== null) {
      flipCardWithSound('river_card', 2500);
    }
  }, [communityCards]);

  const getCardImage = (cardNumber) => {
    if (cardNumber !== null) {
      return require(`../../../assets/images/cards/${cardNumber}.png`);
    }
    return cardBack; // Fallback to card back image if card number is not available
  };

  return (
    <div
      className="absolute flex space-x-4"
      style={{ top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}
    >
      {/* Flop Cards */}
      {['flop_card1', 'flop_card2', 'flop_card3', 'turn_card', 'river_card'].map((cardType, index) => (
        <div key={index} className={`card-container ${flippedCards[cardType] ? 'flipped' : ''}`}>
          <img
            src={flippedCards[cardType] 
              ? getCardImage(communityCards[cardType]) 
              : (cardType === 'turn_card' || cardType === 'river_card' ? cardBack : cardBack)}
            alt={cardType.replace('_', ' ').replace('card', '')}
            className={`card flip-card ${flippedCards[cardType] ? 'show' : ''}`}
          />
        </div>
      ))}
    </div>
  );
};

export default CommunityCards;
