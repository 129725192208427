import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { connectToGame, startGame, getPlayersCards, sendMessage } from './services/websocket'; // Added sendMessage function
import LoadingScreen from './components/LoadingScreen';
import InvitePlayersModal from './components/InvitePlayersModal';
import PlayerDisplay from './components/PlayerDisplay';
import PotDisplay from './components/PotDisplay';
import rearrangePlayers from './utils/rearrangePlayers';
import { decrementCountdown } from './redux/gameSlice';
import './GamePage.css';
import countdownSound from '../../assets/sounds/countdown.mp3';
import ActionButtons from './components/ActionButtons';
import CommunityCards from './components/CommunityCards';

const GamePage = () => {
  const { game_id } = useParams();
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWaitingForPlayers, setIsWaitingForPlayers] = useState(false);
  const [isRaiseActive, setIsRaiseActive] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [message, setMessage] = useState(""); 

  const { status, totalPlayers, myInfo, gameInfo, countdown, blinds, potValue, nextPlayer, communityCards } = useSelector(state => state.game);
  const gameInviteLink = `https://www.mylittlerivercard.com/game-details/${game_id}`;

  useEffect(() => {
    connectToGame(game_id, dispatch); 
    const loadingTimeout = setTimeout(() => setShowLoading(false), 5000);
    return () => clearTimeout(loadingTimeout);
  }, [game_id, dispatch]);

  useEffect(() => {
    setIsModalOpen(status === 'waiting' && totalPlayers <= 1);
  }, [status, totalPlayers]);

  useEffect(() => {
    if (status === 'playing') {
      setIsWaitingForPlayers(false);
      const timer = setTimeout(() => {
        getPlayersCards(game_id);
      }, 3000); 
      return () => clearTimeout(timer);
    }
  }, [status, game_id]);
  
  useEffect(() => {
    if (countdown > 0) {
      const countdownInterval = setInterval(() => {
        dispatch(decrementCountdown());
      }, 1000);
      playCountdownSound();
      return () => clearInterval(countdownInterval);
    }
  }, [countdown, dispatch]);

  const playCountdownSound = () => {
    const audio = new Audio(countdownSound);
    audio.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  };

  const handleStartGame = () => {
    setIsWaitingForPlayers(true);
    startGame(game_id);
  };

  const handleBet = () => {
    console.log('Player bet!');
  };

  const handleFold = () => {
    console.log('Player folded!');
  };

  const handleRaiseClick = () => {
    setIsRaiseActive(!isRaiseActive);
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      sendMessage(game_id, message); // Send the message to WebSocket
      setMessage(""); 
      setIsChatOpen(false);
    }
  };

  const isCurrentPlayerTurn = (nextPlayer && myInfo && nextPlayer.player_id === myInfo.player_id) || false;
  const betAmount = nextPlayer?.bet_amount || 0;
  const playerId = myInfo?.player_id; 
  const availableChips = myInfo?.available_chips || 0;

  if (showLoading) {
    return <LoadingScreen />;
  }

  const players = gameInfo?.players ? rearrangePlayers(myInfo, gameInfo.players) : [];

  return (
    <div className="h-screen w-full flex justify-center items-center relative" style={{ backgroundColor: '#91d31a' }}>
      {isModalOpen && (
        <InvitePlayersModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          gameInviteLink={gameInviteLink}
        />
      )}

      {/* Button to Start Game or New Round */}
      {status === 'waiting' && totalPlayers > 0 && !isWaitingForPlayers && (
        <button className="bg-green-900 text-white font-bold py-2 px-4 rounded-full hover:bg-green-800" onClick={handleStartGame}>
          Start Game
        </button>
      )}

      {status === 'round-completed' && (
        <button className="bg-blue-600 text-white font-bold py-2 px-4 rounded-full hover:bg-blue-500" onClick={handleStartGame}>
          Start New Round
        </button>
      )}

      {isWaitingForPlayers && (
        <div className="waiting-message flex items-center justify-center h-screen">
          <div className="animate-pulse text-center">
            <p className="waiting-text text-lg text-white">Waiting for other players to accept</p>
          </div>
        </div>
      )}

      {countdown > 0 && (
        <div className="countdown-message">
          <p>Game starting in {countdown}...</p>
        </div>
      )}

      {potValue > 0 && <PotDisplay potValue={potValue} />}

      {players.map((player, index) => (
        <PlayerDisplay
          key={index}
          player={player}
          isDealer={player.player_id === blinds.dealer}
          isSmallBlind={player.player_id === blinds.smallBlind}
          isBigBlind={player.player_id === blinds.bigBlind}
        />
      ))}

      {communityCards?.flop_card1 && (
        <CommunityCards communityCards={communityCards} />
      )}

      {isCurrentPlayerTurn && (
        <ActionButtons
          handleBet={handleBet}
          handleFold={handleFold}
          handleRaiseClick={handleRaiseClick}
          betAmount={betAmount}
          gameId={game_id}
          playerId={playerId}
          availableChips={availableChips}
        />
      )}

      {/* Speech Balloon - Chat Box */}
      <div className={`speech-balloon ${isChatOpen ? 'open' : ''}`}>
        {isChatOpen && (
          <div className="chat-box">
            <input
              type="text"
              className="chat-input"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
            />
            <button className="send-button" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        )}
        <button className="speech-balloon-btn" onClick={() => setIsChatOpen(!isChatOpen)}>
          💬
        </button>
      </div>
    </div>
  );
};

export default GamePage;
