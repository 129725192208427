import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import card1Image from '../../../assets/images/Hidden card2.png';
import card2Image from '../../../assets/images/Hidden card2.png';
import winnerIcon from '../../../assets/images/trophy.png';
import { updatePlayerChips, removeMessage } from '../redux/gameSlice';

// Import sound files
import winSound from '../../../assets/sounds/winner_sound.mp3';
import failSound from '../../../assets/sounds/fail.mp3';
import messageSound from '../../../assets/sounds/message_sound.mp3';

const PlayerDisplay = ({ player, isDealer, isSmallBlind, isBigBlind }) => {
  const { playerCards, winner, status, messages, betPlaced } = useSelector((state) => state.game);
  const dispatch = useDispatch();
  const [bettingAnimation, setBettingAnimation] = useState(false);
  const [betAmount, setBetAmount] = useState(0);

  // State for card dealing animation
  const [dealAnimation, setDealAnimation] = useState(false);
  const animationDuration = 1000; // Animation duration in milliseconds

  const positionStyles = {
    bottom: 'bottom-2',
    top: 'top-2',
    left: 'left-2',
    right: 'right-2',
  };

  const getBlindLabel = () => {
    if (isDealer) return 'Dealer';
    if (isSmallBlind) return 'Small Blind';
    if (isBigBlind) return 'Big Blind';
    return '';
  };

  const blindLabel = getBlindLabel();
  const hasPlayerCards = playerCards?.card1 && playerCards?.card2;

  const cardImages =
    player.position === 'bottom' && hasPlayerCards
      ? [
          require(`../../../assets/images/cards/${playerCards.card1}.png`),
          require(`../../../assets/images/cards/${playerCards.card2}.png`),
        ]
      : [card1Image, card2Image]; // Use hidden cards for other players

  const isWinner = status === 'winner_declared' && winner === player.player_id;

  // Get the player's message if any
  const playerMessage = messages.find((msg) => msg.player_id === player.player_id);

  // Find the player's bet
  const playerBet = betPlaced.find((bet) => bet.player_id === player.player_id);

  // UseEffect for handling the game status changes
  useEffect(() => {
    if (status === 'winner_declared') {
      const audio = new Audio(winner === player.player_id ? winSound : failSound);
      audio.play();
    }

    if (status === 'starting') {
      if (isSmallBlind) {
        dispatch(updatePlayerChips({ player_id: player.player_id, new_chips: 29 }));
        setBetAmount(-1); // Small blind bet amount
        setBettingAnimation(true);
      }
      if (isBigBlind) {
        dispatch(updatePlayerChips({ player_id: player.player_id, new_chips: 28 }));
        setBetAmount(-2); // Big blind bet amount
        setBettingAnimation(true);
      }

      // Hide animation after a short delay
      setTimeout(() => {
        setBettingAnimation(false);
      }, 2000); // Animation duration
    }
  }, [status, winner, player.player_id, dispatch, isSmallBlind, isBigBlind]);

  // UseEffect for card dealing animation
  useEffect(() => {
    if (hasPlayerCards) {
      setDealAnimation(true);

      // Stop the animation after the duration
      setTimeout(() => {
        setDealAnimation(false);
      }, animationDuration);
    }
  }, [hasPlayerCards]);

  // UseEffect for handling message display and auto-removal after 5 seconds
  useEffect(() => {
    if (playerMessage) {
      const messageAudio = new Audio(messageSound);
      messageAudio.play();
      const timer = setTimeout(() => {
        dispatch(removeMessage(playerMessage.id));
      }, 5000); // Remove message after 5 seconds

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [playerMessage, dispatch]);

  return (
    <div
      className={`flex flex-col items-center justify-center absolute ${positionStyles[player.position]}`}
    >
      {/* Card Images */}
      <div className="flex space-x-4 mb-0">
        {hasPlayerCards &&
          cardImages.map((card, index) => (
            <img
              key={index}
              src={card}
              alt={`Card ${index + 1}`}
              style={{ width: 70, height: 90 }}
              className={dealAnimation ? 'deal-animation' : ''}
            />
          ))}
      </div>

      {/* Player Info */}
      <div
        className={`bg-black text-white rounded-full px-4 py-2 flex items-center w-48 relative ${
          isWinner ? 'winner-animation' : ''
        }`}
      >
        <p className="text-left">{player.username}</p>
        <p className="ml-auto text-right">{player.available_chips}</p>

        {isWinner && (
          <img
            src={winnerIcon}
            alt="Winner"
            className="absolute -top-6 -right-6 w-12 h-12 animate-bounce"
          />
        )}
      </div>

      {/* Enhanced Betting Animation */}
      {bettingAnimation && (
        <div className="bet-animation">
          <div className="chip">{betAmount}</div>
        </div>
      )}

      {/* Display Player's Bet */}
      {playerBet && (
        <div className="bg-yellow-500 text-black rounded-full px-3 py-1 mt-2">
          Bet: {playerBet.amount} ({playerBet.bet_type})
        </div>
      )}

      {blindLabel && (
        <p className={`text-red-500 font-bold mt-2 ${blindLabel ? 'text-lg' : ''}`}>
          {blindLabel}
        </p>
      )}

      <img
        src={player.user_image}
        alt={player.username}
        style={{ width: 60, height: 60, borderRadius: '50%', marginTop: 8 }}
        className={isWinner ? 'animate-pulse' : ''}
      />

      {/* Player Message */}
      {playerMessage && (
        <div className="bg-black text-white px-3 py-2 mt-2 rounded-full fade-message">
          {playerMessage.message}
        </div>
      )}
    </div>
  );
};

export default PlayerDisplay;
