const rearrangePlayers = (myInfo, allPlayers = []) => {
  if (!myInfo || !allPlayers.length) {
      return []; // Return an empty array if myInfo or allPlayers are not available
  }

  const totalPositions = 4; // Assuming 4 positions: bottom, top, left, right
  const positions = ['bottom', 'right', 'top', 'left']; // Mapping of relative positions

  // Remove current user from the list of players
  const otherPlayers = allPlayers.filter(player => player.username !== myInfo.username);

  // Function to get the relative position
  const getRelativePosition = (playerPosition, myPosition) => {
      const relativePos = (playerPosition - myPosition + totalPositions) % totalPositions;
      return positions[relativePos]; // Return the correct position based on the index
  };

  // Arrange the other players
  const arrangedPlayers = otherPlayers.map(player => ({
      ...player,
      position: getRelativePosition(player.position, myInfo.position),
  }));

  // Return the arranged players with myInfo at the bottom
  return [
      {
          ...myInfo,
          position: 'bottom', // Always at the bottom for the current user
      },
      ...arrangedPlayers,
  ];
};

export default rearrangePlayers;
