import React from 'react';
import chip1 from '../../../assets/images/chip1.png'
import chip2 from '../../../assets/images/chip2.png'

const PotDisplay = ({ potValue }) => {
  return (
    <div className="absolute flex items-center justify-between bg-[#35702E] text-white rounded-2xl py-3 px-6"
         style={{ top: '55%', left: '50%', transform: 'translateX(-50%)', width: '300px', borderRadius: '30px' }}>
          <div className="flex items-center">
          <img src={chip1} alt="Chip 1" style={{ width: '40px', height: '40px', marginRight: '8px' }} />
          <img src={chip2} alt="Chip 2" style={{ width: '40px', height: '40px', marginRight: '8px' }} />
          </div>
      <p className="text-xl font-semibold">Pot</p>
      <p className="text-2xl font-bold">{potValue}</p>
    </div>
  );
};

export default PotDisplay;
