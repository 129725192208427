import { createSlice } from '@reduxjs/toolkit';
import { findBlinds } from '../utils/findBlinds ';

const initialState = {
  isLoading: true,
  gameInfo: {
    players: [],
  },
  currentRound: 0,
  totalPlayers: 0,
  status: 'waiting',
  blinds: {
    dealer: null,
    smallBlind: null,
    bigBlind: null,
  },
  myInfo: null,
  countdown: 0,
  playerCards: {},
  potValue: 0,
  communityCards: {
    flop_card1: null,
    flop_card2: null,
    flop_card3: null,
    turn_card: null,
    river_card: null,
  },
  nextPlayer: null,
  winner: null,
  messages: [],
  betPlaced: [],
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    updateGameState(state, action) {
      state.gameInfo = {
        ...action.payload,
        players: Array.isArray(action.payload.players) ? action.payload.players : [],
      };
      state.totalPlayers = action.payload.current_players || 0;
      state.status = action.payload.status || 'waiting';
      state.currentRound = action.payload.current_round || 0;
      state.isLoading = false;
    },

    updatePlayersList(state, action) {
      if (Array.isArray(action.payload)) {
        state.gameInfo.players = action.payload;
      } else {
        console.error("Expected array of players but got:", action.payload);
      }
    },

    playerJoined(state, action) {
      if (action.payload && typeof action.payload === 'object') {
        state.gameInfo.players = [...state.gameInfo.players, action.payload];
        state.totalPlayers += 1;
      } else {
        console.error("Expected a player object but got:", action.payload);
      }
      state.status = 'waiting';
    },

    gameStarting(state, action) {
      const { dealerPosition } = action.payload;
      state.countdown = 7; // Starting countdown to begin game
      try {
        const blinds = findBlinds(dealerPosition, state.gameInfo.players, state.myInfo);
        state.blinds = {
          dealer: dealerPosition,
          smallBlind: blinds.smallBlind,
          bigBlind: blinds.bigBlind,
        };
        state.status = 'starting';
      } catch (error) {
        console.error('Error finding blinds:', error.message);
      }
    },

    updateMyInfo(state, action) {
      state.myInfo = action.payload;
    },

    decrementCountdown(state) {
      if (state.countdown > 0) {
        state.countdown -= 1;
      }
    },

    updatePlayerCards(state, action) {
      const { potValue, playerCards } = action.payload;
      state.potValue = potValue;
      state.playerCards = playerCards;
      state.status = 'playing';
    },

    updateCommunityCards(state, action) {
      const { flop_card1, flop_card2, flop_card3, turn_card, river_card } = action.payload;

      console.log(flop_card1, flop_card2, flop_card3, turn_card, river_card, "here here the community cards states");

      if (flop_card1 !== undefined) {
        state.communityCards.flop_card1 = flop_card1;
      }
      if (flop_card2 !== undefined) {
        state.communityCards.flop_card2 = flop_card2;
      }
      if (flop_card3 !== undefined) {
        state.communityCards.flop_card3 = flop_card3;
      }
      if (turn_card !== undefined) {
        state.communityCards.turn_card = turn_card;
      }
      if (river_card !== undefined) {
        state.communityCards.river_card = river_card;
      }
    },

    setNextPlayer(state, action) {
      state.nextPlayer = action.payload;
    },

    setWinner(state, action) {
      console.log("here inside the setWinner", action.payload.player_id)
      state.winner = action.payload.player_id; 
      state.status = 'winner_declared';
    },
    

    resetGameState(state) {
      console.log('inside teh reset game state')
      state.status = 'round-completed';
      state.blinds = {
        dealer: null,
        smallBlind: null,
        bigBlind: null,
      };
      state.potValue = 0;
      state.playerCards = {};
      state.communityCards = {
        flop_card1: null,
        flop_card2: null,
        flop_card3: null,
        turn_card: null,
        river_card: null,
      };
      state.nextPlayer = null;
      state.winner = null;
    },

    updatePlayerBet(state, action) {
      const { player_id, available_chips, pot_value } = action.payload.details;

      const player = state.gameInfo.players.find(p => p.player_id === player_id);
      if (player) {
        player.available_chips = available_chips;
      } else if (state.myInfo && state.myInfo.player_id === player_id) {
        state.myInfo.available_chips = available_chips;
      }

      state.potValue = pot_value;
    },

    newBetUpdate(state, action) {
      const { player_id, amount, bet_type } = action.payload.details;
      const existingBet = state.betPlaced.find(bet => bet.player_id === player_id);
      console.log(state.betPlaced, "here is the data from redux")
      if (existingBet) {
        state.betPlaced = [];
      }
      state.betPlaced.push({ player_id, amount, bet_type });
      console.log(state.betPlaced, "here is the data from redux")
    },
    


    newMessageReceived(state, action) {
      const { player_id, message } = action.payload;
      state.messages.push({
        player_id,
        message,
        id: Date.now(),
      });
    },

    removeMessage(state, action) {
      state.messages = state.messages.filter(msg => msg.id !== action.payload);
    },

    updatePlayerChips(state, action) {
      const { player_id, new_chips } = action.payload;
      console.log("inside the updatePlayerChips slice", player_id, new_chips);

      // First check if the player_id matches myInfo
      if (state.myInfo && state.myInfo.player_id === player_id) {
        state.myInfo.available_chips = new_chips;
      } else {
        // Find the player in gameInfo.players and update the available chips
        const player = state.gameInfo.players.find(p => p.player_id === player_id);
        if (player) {
          player.available_chips = new_chips;
        } else {
          console.error(`Player with id ${player_id} not found`);
        }
      }
      state.status = 'playing';
    },
  },
});

// Export the actions
export const {
  updateGameState,
  updatePlayersList,
  playerJoined,
  updateMyInfo,
  gameStarting,
  decrementCountdown,
  updatePlayerCards,
  updateCommunityCards,
  setNextPlayer,
  setWinner,
  resetGameState, // New action
  updatePlayerBet,
  updatePlayerChips,
  newMessageReceived,
  removeMessage,
  newBetUpdate,
} = gameSlice.actions;

export const handleSetWinner = (player_id) => (dispatch) => {
  dispatch(setWinner(player_id));
  setTimeout(() => {
    dispatch(resetGameState());
  }, 7000);
};


export default gameSlice.reducer;
