export function findBlinds(dealerPosition, players, myInfo) {
  if (!myInfo || typeof myInfo !== 'object') {
    throw new Error('myInfo is required and must be an object.');
  }

  console.log(dealerPosition)
  // Add myInfo to the players list if it is not already present
  const allPlayers = [...players];
  const myInfoInPlayers = allPlayers.some(player => player.player_id === myInfo.player_id);

  if (!myInfoInPlayers) {
    allPlayers.push(myInfo);
  }

  // Sort players by their position (this is useful when positions are not in order)
  const sortedPlayers = allPlayers.sort((a, b) => a.position - b.position);

  // If we have less than 2 players, we cannot calculate blinds
  if (sortedPlayers.length < 2) {
    throw new Error('Not enough players to determine blinds.');
  }

  // Find the dealer index in the sorted player array
  const dealerIndex = sortedPlayers.findIndex(player => player.position === dealerPosition);
  if (dealerIndex === -1) {
    throw new Error('Dealer position is invalid or not found among players.');
  }

  // Utility function to get the next player index in a circular manner
  const getNextPlayerIndex = (currentIndex) => (currentIndex + 1) % sortedPlayers.length;

  // Small blind is the player after the dealer
  const smallBlindIndex = getNextPlayerIndex(dealerIndex);
  const smallBlindPlayer = sortedPlayers[smallBlindIndex];

  // Big blind is the player after the small blind
  const bigBlindIndex = getNextPlayerIndex(smallBlindIndex);
  const bigBlindPlayer = sortedPlayers[bigBlindIndex];

  // Return the small and big blind player IDs
  return {
    smallBlind: smallBlindPlayer.player_id,
    bigBlind: bigBlindPlayer.player_id
  };
}
